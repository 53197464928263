<template>
	<section>
		<h1 class="is-size-1 mb-6" v-translate>Onepager report</h1>
		<b-message :title="$gettext('No access')" type="is-danger" has-icon :closable="false">
			<translate>
				It seems you have no access to this application. Contact this email address to get your permissions fixed:
			</translate>
			&#32;
			<span v-html="emailLink"></span>.
		</b-message>
	</section>
</template>

<script>
export default {
	data() {
		return {
			supportEmail: 'support@moveup.care',
			subject: 'Access permissions for Onepager report',
			body: 'Could you please grant me access to Onepager report? Thanks.',
		};
	},
	computed: {
		mailto() {
			return `mailto:${this.supportEmail}?subject=${this.subject}&body=${this.body}`;
		},
		emailLink() {
			return `<a href="${this.mailto}">${this.supportEmail}</a>`;
		},
	},
};
</script>
